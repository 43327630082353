import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { MediaCard } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { DesktopIcon } from '@entur/icons';
import mediacard from './MediaCard.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<MediaCard\n  title=\"Hva gjør Entur?\"\n  description=\"Entur eier og styrer salgssystemet for togbilletter og samler data om kollektivtrafikken i Norge, slik at det blir enklere å velge en bærekraftig reise.\"\n  href=\"https://www.entur.no\"\n  style={{ width: \'31rem\' }}\n>\n  <img\n    style={{ width: \'31rem\' }}\n    src={mediacard}\n    alt=\"Buss som kjører over bro i fjelllandskap\"\n  />\n</MediaCard>'} __scope={{
      props,
      DefaultLayout,
      MediaCard,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DesktopIcon,
      mediacard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <MediaCard title="Hva gjør Entur?" description="Entur eier og styrer salgssystemet for togbilletter og samler data om kollektivtrafikken i Norge, slik at det blir enklere å velge en bærekraftig reise." href="https://www.entur.no" style={{
        width: '31rem'
      }} mdxType="MediaCard">
    <img style={{
          width: '31rem'
        }} src={mediacard} alt="Buss som kjører over bro i fjelllandskap" />
  </MediaCard>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "mediacard"
    }}>{`MediaCard`}</h3>
    <ImportStatement imports="MediaCard" mdxType="ImportStatement" />
    <Props of={MediaCard} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Card Media er en skyggefull boks som viser innhold og handling om et emne.
I motsetnings til et vanlig Card, kan Card Media inneholde video, bilde og grafikk for å forsterke innholdet.
Card Media gir et kort sammendrag av innholdet, og lenker videre til en ny side med ytterligere detaljer.
Den skal være enkel å skanne etter relevant informasjon.`}</p>
    <p>{`Card Media kan for eksempel brukes som navigasjon til en artikkel eller bloggpost.
Komponenten skal ha en beskrivende tekst under tittelen.
Teksten bør være kort og konsis, på max 3 linjer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      